<template lang="pug">
//- 新增排課
.new-scheduled-index.page-Index
  //- 內容 TOP (內有 搜尋bar 及 通知區塊 )
  ContentTop

  .new-scheduled-top
    .new-scheduled-title 新增排課
    .new-scheduled-text 選擇您想排的課程

  //- 切換內容
  .new-scheduled-content
    //- 點數購買
    .new-scheduled-view
      .row.home-content
        .content-left-1.padding-right-none.buy-left
          .new-scheduled-from-box.form
            .new-scheduled-from.mr-10
              //- 姓名
              .form-group
                .form-group-title 課程名稱
                input.form-group-input(
                  type="text",
                  placeholder="請輸入課程名稱"
                  v-model="course.course_name"
                )

              //- 一堂課程時間
              .form-group
                .form-group-title 一堂課程時間
                .radio-group
                  .radio-item.click-cursor(
                    @click="changeRadio('min',30)" 
                    :class="course.course_minutes == 30 ? 'bg-blue-1' : 'bg-gray-1'"
                  )  30分鐘
                  .radio-item.click-cursor(
                    @click="changeRadio('min',40)" 
                    :class="course.course_minutes == 40 ? 'bg-blue-1' : 'bg-gray-1'"
                  )  40分鐘
                  .radio-item.click-cursor(
                    @click="changeRadio('min',50)" 
                    :class="course.course_minutes == 50 ? 'bg-blue-1' : 'bg-gray-1'"
                  )  50分鐘
                  .radio-item.click-cursor(
                    @click="changeRadio('min',60)" 
                    :class="course.course_minutes == 60 ? 'bg-blue-1' : 'bg-gray-1'"
                  )  60分鐘
                  .radio-item.click-cursor(
                    @click="changeRadio('min',90)" 
                    :class="course.course_minutes == 90 ? 'bg-blue-1' : 'bg-gray-1'"
                  )  90分鐘
                  .radio-item.click-cursor(
                    @click="changeRadio('min',120)" 
                    :class="course.course_minutes == 120 ? 'bg-blue-1' : 'bg-gray-1'"
                  )  120分鐘
                  .clearfix


              //- 互動人數
              .form-group
                .form-group-title 互動人數 (1-15人)
                select.form-group-input(v-model="course.course_room_limit")
                  option(v-for="n in 15" :value="n") {{ n }}人

              //- 直播人數
              .form-group
                .form-group-title 直播人數 *(0-無限)
                input.form-group-input(
                  type="text",
                  placeholder="請輸入直播人數 "
                  v-model="course.course_live_limit"
                )

              //- 語言
              .form-group
                .form-group-title 語言
                .radio-group
                  .radio-item.click-cursor(
                    @click="changeRadio('lang','en')" 
                    :class=" course.course_language == 'en' ? 'bg-blue-1' : 'bg-gray-1'"
                  ) 美語
                  .radio-item.click-cursor(
                    @click="changeRadio('lang','ja')" 
                    :class=" course.course_language == 'ja' ? 'bg-blue-1' : 'bg-gray-1'"
                  ) 日語
                  .radio-item.click-cursor(
                    @click="changeRadio('lang','ko')" 
                    :class=" course.course_language == 'ko' ? 'bg-blue-1' : 'bg-gray-1'"
                  ) 韓語
                  .clearfix

              //- 師資
              .form-group
                .form-group-title 師資
                .radio-group
                  .radio-item.click-cursor(
                    @click="changeRadio('teach',1)" 
                    :class=" course.course_teacher_type == 1 ? 'bg-blue-1' : 'bg-gray-1'"
                  ) 中師
                  .radio-item.click-cursor(
                    @click="changeRadio('teach',2)" 
                    :class=" course.course_teacher_type == 2 ? 'bg-blue-1' : 'bg-gray-1'"
                  ) 母語教師
                  .clearfix

              //- 使用教材
              .form-group
                .form-group-title 使用教材
                .radio-group
                  .radio-item.click-cursor(
                    @click="changeRadio('tool',1)" 
                    :class=" course.course_material_type == 1 ? 'bg-blue-1' : 'bg-gray-1'"
                  ) 地球村特許教材
                  .radio-item.click-cursor(
                    @click="changeRadio('tool',2)" 
                    :class=" course.course_material_type == 2 ? 'bg-blue-1' : 'bg-gray-1'"
                  ) 自訂教材
                  .clearfix

            .new-scheduled-from
              //- 課程時間
              .form-group
                .form-group-title 課程時間
                .input-date-box(
                  v-for="(dateItem,index) in course.course_items"
                  :key="'course-time-' + ( index + 1 )"
                )
                  input.form-group-input.input-date(
                    type="datetime-local"
                    v-model="dateItem.date"
                  )
                  .delete-date-btn.click-cursor(@click="deleteDateInput(index)") －
                .add-date.click-cursor(@click="addDateInput()") 新增年月日時分


              //- 姓名
              //- .form-group.mt-3
                .form-group-title 課程補充描述
                textarea.form-group-textarea(
                  placeholder="請輸入課程補充描述"
                )

            .clearfix

        .content-right-1
          .result-group
            .result-title 排課明細計算

            .result-item
              .result-item-title 一堂課程時間
              .result-item-content 
                .result-item-text {{ course.course_minutes }} 分鐘
                .result-item-out {{ amount.minutes_point }} 點

            .result-item
              .result-item-title 互動人數
              .result-item-content 
                .result-item-text {{ course.course_room_limit }} 人
                .result-item-out {{  amount.room_point  }} 點


            .result-item
              .result-item-title 師資類型
              .result-item-content 
                .result-item-text 
                  span(v-if="course.course_teacher_type == 1") 華語教師
                  span(v-if="course.course_teacher_type == 2") 母語教師
                .result-item-out {{  amount.teacher_point  }} 點

            .result-item
              .result-item-title 使用教材
              .result-item-content 
                .result-item-text 
                  span(v-if="course.course_material_type == 1") 特許教材
                  span(v-if="course.course_material_type == 2") 自訂教材
                .result-item-out {{ amount.material_point }} 點


            .result-hr
            .result-title 排課總計
            .result-item
              .result-item-title 排課堂數
              .result-item-content 
                .result-item-text {{ course_items.length }} 堂
                //- .result-num x{{ course_items.length }} 
                .result-item-out x{{ course_items.length }} 

            .result-hr
            .result-total
              .total-title 扣點總計
              .total-point {{ amount.total_point }} 點

            button.check-cost.btn-active(
              type="button" 
              @click="createMainCourse"
            ) 確認扣點

</template>
<script>
import $ from 'jquery'
import ContentTop from '@/components/content-top/content-top'
import TabTwoContent from '@/components/profile/tab-2-buypoint'


export default {
  name: 'CreateBusinessCourse',
  components: {
    ContentTop,
    TabTwoContent,
  },
  data () {
    return {
      course: {
        course_name: '',
        course_minutes: 60,
        course_room_limit: 1,
        course_live_limit: 999,
        course_language: '',
        course_teacher_type: '',
        course_material_type: '',
        course_material_path: '',
        course_items: [{ date: '' }]
      },
      amount: {
        minutes_point: 0,
        room_point: 0,
        material_point: 0,
        teacher_point: 0,
        courses_point: 0,
        total_point: 0
      },
      createing: false,
      min_type: 30,
      lang_type: 'en',
      teach_type: 1,
      tool_type: 1,
      course_items: [{ date: '' }]
    };
  },
  watch: {
    // 更換課程長度
    'course.course_minutes' () {
      this.countPointer()
    },
    // 更換互動人數
    'course.course_room_limit' () {
      this.countPointer()
    },
    // 更換教師母語
    'course.course_teacher_type' () {
      this.countPointer()
    },
    // 更換教材類型
    'course.course_material_type' () {
      this.countPointer()
    }
  },
  async created () {
    // 請求進行更新學員資料
    // await this.$root.apis.getSystemInfo()
    // 關閉載入中畫面
    await this.$root.closeLoading()
    // 預設計算價金
    this.countPointer()
  },
  mounted () {
  },
  computed: {
  },
  methods: {
    //- 回至頂部
    goTop () {
      $('.profile-index').animate({
        scrollTop: 0
      }, 200)
    },
    // 變更選項
    changeRadio (_radio, _type) {
      if (_radio == 'min') {
        this.course.course_minutes = _type
      }
      if (_radio == 'lang') {
        this.course.course_language = _type
      }
      if (_radio == 'teach') {
        this.course.course_teacher_type = _type
      }
      if (_radio == 'tool') {
        this.course.course_material_type = _type
      }
    },
    // 增加課堂時間
    addDateInput () {
      this.course.course_items.push({ date: '' })
      this.countPointer()
    },
    // 移除課堂時間
    deleteDateInput (_index) {
      console.log(_index);
      this.course.course_items.splice(_index, 1)
      this.countPointer()
    },
    // 重置課堂清單
    resetCourseItems () {
      let vm = this
      this.course.course_items = []
      this.course.course_items.forEach(function (_course) {
        vm.course.course_items.push({
          time: vm.$root.common.getNowTime('datetime', _course.date)
        })
      })
    },
    // 重新計算點數價格
    countPointer () {
      // 處理預設的金額
      let pointerAmount = 0

      // 處理需要重置的點數
      this.amount.teacher_point = 0
      this.amount.material_point = 0

      // 基本點數設置 + 母語教師設置
      if (this.course.course_minutes == '30') {
        this.amount.minutes_point = 3
        pointerAmount += 3
        if (this.course.course_teacher_type == '2') {
          this.amount.teacher_point = 0
          pointerAmount += 0
        }
      }
      if (this.course.course_minutes == '40') {
        this.amount.minutes_point = 4
        pointerAmount += 4
        if (this.course.course_teacher_type == '2') {
          this.amount.teacher_point = 0
          pointerAmount += 0
        }
      }
      if (this.course.course_minutes == '50') {
        this.amount.minutes_point = 5
        pointerAmount += 5
        if (this.course.course_teacher_type == '2') {
          this.amount.teacher_point = 0
          pointerAmount += 0
        }
      }
      if (this.course.course_minutes == '60') {
        this.amount.minutes_point = 6
        pointerAmount += 6
        if (this.course.course_teacher_type == '2') {
          this.amount.teacher_point = 0
          pointerAmount += 0
        }
      }
      if (this.course.course_minutes == '90') {
        this.amount.minutes_point = 9
        pointerAmount += 9
        if (this.course.course_teacher_type == '2') {
          this.amount.teacher_point = 0
          pointerAmount += 0
        }
      }
      if (this.course.course_minutes == '120') {
        this.amount.minutes_point = 12
        pointerAmount += 12
        if (this.course.course_teacher_type == '2') {
          this.amount.teacher_point = 0
          pointerAmount += 0
        }
      }

      // 互動人數計算
      let roomPoint = (this.course.course_room_limit > 1) ? ((this.course.course_room_limit - 1) * 2) : 0
      pointerAmount += roomPoint
      this.amount.room_point = roomPoint

      // 自訂教材計算
      if (this.course.course_material_type == 2) {
        pointerAmount += 5
        this.amount.material_point = 5
      }

      // 開幾堂課就乘以幾倍
      pointerAmount = pointerAmount * this.course.course_items.length

      // 計算總共的價金
      this.pointerAmount = pointerAmount
      this.amount.total_point = pointerAmount
    },
    // 確認表單填寫情況
    checkDataField () {
      let vm = this

      if (!this.course.course_name) {
        this.$root.openOptionView({
          title: "資訊不完整",
          text_1: '課程主題名稱是必填的哦',
          text_2: '',
          course_text: '',
          option_1: "瞭解",
          option_2: '',
          option_1_fun: false,
          option_2_fun: false
        })
        return false
      }

      if (!this.course.course_minutes) {
        this.$root.openOptionView({
          title: "資訊不完整",
          text_1: '課程長度是必填的哦',
          text_2: '',
          course_text: '',
          option_1: "瞭解",
          option_2: '',
          option_1_fun: false,
          option_2_fun: false
        })
        return false
      }

      if (!this.course.course_room_limit) {
        this.$root.openOptionView({
          title: "資訊不完整",
          text_1: '課程互動人數是必填的哦',
          text_2: '',
          course_text: '',
          option_1: "瞭解",
          option_2: '',
          option_1_fun: false,
          option_2_fun: false
        })
        return false
      }

      if (!this.course.course_language) {
        this.$root.openOptionView({
          title: "資訊不完整",
          text_1: '課程語系是必填的哦',
          text_2: '',
          course_text: '',
          option_1: "瞭解",
          option_2: '',
          option_1_fun: false,
          option_2_fun: false
        })
        return false
      }

      if (!this.course.course_teacher_type) {
        this.$root.openOptionView({
          title: "資訊不完整",
          text_1: '課程師資是必填的哦',
          text_2: '',
          course_text: '',
          option_1: "瞭解",
          option_2: '',
          option_1_fun: false,
          option_2_fun: false
        })
        return false
      }

      if (!this.course.course_material_type) {
        this.$root.openOptionView({
          title: "資訊不完整",
          text_1: '課程教材種類是必填的哦',
          text_2: '',
          course_text: '',
          option_1: "瞭解",
          option_2: '',
          option_1_fun: false,
          option_2_fun: false
        })
        return false
      }

      if (!this.course.course_items.length) {
        this.$root.openOptionView({
          title: "資訊不完整",
          text_1: '課程時間排程是必填的哦',
          text_2: '',
          course_text: '',
          option_1: "瞭解",
          option_2: '',
          option_1_fun: false,
          option_2_fun: false
        })
        console.log('--> 課程時間排程:', this.course.course_items);

        return false
      }

      // 檢查課程時間是否大於一週後
      let checkTime = true
      let minTime = this.$root.common.getNowTime('timestamp') + (60 * 60 * 24 * 7)
      console.log('=> 目前時間: ' + minTime)
      this.course.course_items.forEach(function (_item) {
        if (!checkTime) {
          return
        }

        if (!_item) {
          console.log('=> 課程時間: 未指定上課時間', _item);
          checkTime = false
          return
        }

        let targetTime = vm.$root.common.getNowTime('timestamp', _item.date)
        if (targetTime <= minTime) {
          console.log('=> 課程時間: [異常]', _item);
          checkTime = false
        }
      })

      if (!checkTime) {
        this.$root.openOptionView({
          title: "資訊不完整",
          text_1: '課程時間必須在一週之後哦',
          text_2: '',
          course_text: '',
          option_1: "瞭解",
          option_2: '',
          option_1_fun: false,
          option_2_fun: false
        })
        return false
      }

      return true
    },
    // 請求建立課程
    async createMainCourse () {
      if (this.createing) {
        return
      }

      this.createing = true

      await this.countPointer()

      if (!this.checkDataField()) {
        this.createing = false
        return false
      }

      // 處理扣點點數
      this.course.amount = await this.pointerAmount

      let vm = this
      await this.$http.post('student/business/CreateCourse', this.course).then(function (_response) {
        this.createing = false
        this.$root.openOptionView({
          title: "建立課程資料",
          text_1: '成功建立課程資料',
          text_2: '',
          course_text: '',
          option_1: "瞭解",
          option_2: '',
          option_1_fun: function () {
            vm.$root.goPage('scheduled_detail', 1, { id: _response.body.data })
          },
          option_2_fun: false
        })

      }, function (_error) {

        this.createing = false
        this.$root.openOptionView({
          title: "建立發生問題",
          text_1: _error.body.message,
          text_2: '',
          course_text: '',
          option_1: "瞭解",
          option_2: '',
          option_1_fun: false,
          option_2_fun: false
        })

      })
    }
  }
}
</script>
<style lang="sass">
.radio-group
  margin-top: 0.3rem
  margin-bottom: 1rem
  .radio-item
    float: left
    height: 2.6rem
    line-height: 2.6rem
    margin-right: .5rem
    border-radius: 5px
    padding: 0 1.3rem
    font-size: .7rem
    font-weight: 500
    text-align: center
    &:active
      opacity: .6
  .bg-blue-1
    background: #2e50ff
    color: #fff
    font-size: .8rem
  .bg-gray-1
    background: #f3f3f3
    color: #91a1c1
</style>
